import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "./dialog";

interface ImageDialogProps {
  image: string;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

export const ImageDialog = ({
  image,
  isModalOpen,
  setIsModalOpen,
}: ImageDialogProps) => {
  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Photo Preview</DialogTitle>
        </DialogHeader>

        <DialogDescription className="sr-only">
          Larger Preview
        </DialogDescription>

        <img
          src={image}
          alt="Larger preview"
          className="h-auto max-h-[80vh] w-full rounded object-cover"
        />
      </DialogContent>
    </Dialog>
  );
};
