import type { Photo, ProfilePublic, Review } from "@ddr/models";
import { Button, Rating, ReactTextLink } from "@ddr/ui";
import React, { useEffect, useState } from "react";
import ProfileChecklist from "./profile-checklist";
import { ChevronLeft } from "lucide-react";
import { publicMoAdapter } from "../storefront-client";
import { API_URL, PILOT_PROFILE_URL } from "../config";
import { formatDate } from "@ddr/utils";
import { handleError } from "../utils/error";
import { fetchJson } from "../utils/fetch";

interface Prop {
  profile: ProfilePublic;
  onClose: () => void;
  onContactPilotClick: () => void;
  existingConnection?: boolean;
}

const SectionHeading = ({ children }: { children: React.ReactNode }) => (
  <h2 className="mb-1 text-lg font-bold">{children}</h2>
);

const Section = ({
  children,
  title,
  className,
}: {
  children: React.ReactNode;
  title?: string;
  className?: string;
}) => (
  <section className={`mb-7 ${className}`}>
    {title && <SectionHeading>{title}</SectionHeading>}
    {children}
  </section>
);

export default function ProfileDetailView({
  profile,
  onClose,
  onContactPilotClick,
}: Prop) {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [reviews, setReviews] = useState<Review[]>([]);

  useEffect(() => {
    // Note: had an issue where photos were staying in place from previous pilot
    // Make sure to clear photos (and reviews) if profile doesn't have photos/reviews

    async function fetchPhotos() {
      if (!profile.photos) {
        setPhotos([]);
        return;
      }
      const res = await publicMoAdapter.getPhotos(profile.photos);
      setPhotos(res);
    }
    fetchPhotos().catch((e) => {
      handleError(e);
      setPhotos([]);
    });
  }, [profile.photos]);

  useEffect(() => {
    async function fetchData() {
      const res = await fetchJson<Review[]>(
        `${API_URL}/profiles/${profile.account_handle}/reviews`,
      );
      setReviews(res.data);
    }
    fetchData().catch((e) => {
      handleError(e);
      setReviews([]);
    });
  }, [profile.account_handle]);

  const site = profile.sites[0];

  const logo =
    typeof site.logo === "string" ? site.logo : site.logo?.reference?.image.url;
  const headshot =
    typeof profile.headshot === "string"
      ? profile.headshot
      : profile.headshot.reference?.image.url;

  const useNameInTitle = site.companyName && (site.companyDescription || logo);
  const companySectionTitle = useNameInTitle ? site.companyName : "Company";
  const companyNameInSection = useNameInTitle ? undefined : (
    <p>{site.companyName}</p>
  );

  return (
    <div className="m-auto max-w-xl rounded-lg bg-white p-5">
      <button
        className="relative bottom-2 right-2 mb-1 flex font-bold"
        onClick={onClose}
      >
        <ChevronLeft /> Back
      </button>

      <Section className="flex flex-col items-center gap-3">
        <div className="flex items-center gap-3">
          <img src={headshot} className="h-28 w-28 rounded-full object-cover" />
        </div>
        <Button onClick={onContactPilotClick}>Contact Pilot</Button>
        <ReactTextLink
          href={`${PILOT_PROFILE_URL}/${site.slug}`}
          text="Profile Page"
          external
        />
      </Section>

      <Section title={`${profile.firstName} ${profile.lastName}`}>
        <p>{profile.about_pilot}</p>
      </Section>

      {site.companyName && (
        <Section title={companySectionTitle}>
          {logo && (
            <img
              src={logo}
              alt={site.companyName}
              className="my-4 h-28 rounded-md object-cover"
            />
          )}
          {companyNameInSection}
          {site.companyDescription && <p>{site.companyDescription}</p>}
        </Section>
      )}

      <Section title="What's Included">
        <ProfileChecklist profile={profile} />
      </Section>

      <Section>
        <h2 className="mb-3 text-lg font-bold">Reviews</h2>
        <div className="mb-4">
          <Rating rating={profile.rating}></Rating>
          {profile.numberOfRatings === 0 ? (
            <p>No reviews yet</p>
          ) : (
            <p className="fon">
              {profile.numberOfRatings} ratings w/ average of {profile.rating}
            </p>
          )}
        </div>

        <ul>
          {reviews.map((review) => (
            <li key={review.id} className="mb-5">
              <div className="mb-1 flex items-start gap-2">
                <h3 className="font-bold">{formatDate(review.date)}</h3>
                <Rating rating={review.rating} />
              </div>
              <p>{review.review}</p>
            </li>
          ))}
        </ul>
      </Section>

      {photos.length > 0 && (
        <Section title="Photos">
          <div className="flex flex-wrap gap-3">
            {photos.map((photo) => (
              <img
                key={photo.id}
                src={photo.image.url}
                alt={photo.alt ?? "Photo uploaded with review"}
                className="basis-full rounded-md object-cover"
              />
            ))}
          </div>
        </Section>
      )}
    </div>
  );
}
