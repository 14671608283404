import type { ProfilePublic } from "@ddr/models";
import { UserCircle2 } from "lucide-react";

type SizeOption = "small" | "medium" | "large" | "extraLarge";

export default function Avatar({
  profile,
  size = "medium",
  title,
  useBuiltInHeader,
}: {
  profile: ProfilePublic;
  size?: SizeOption;
  title?: string;
  useBuiltInHeader?: boolean;
}) {
  const site = profile.sites[0];
  const imageUrl =
    typeof profile.headshot === "string"
      ? profile.headshot
      : profile.headshot.reference?.image.url;
  const titleToUse = title ?? `${profile.firstName} ${profile.lastName}`;

  const sizeClassMap: Record<SizeOption, string> = {
    small: "h-12 w-12",
    medium: "h-16 w-16",
    large: "h-18 w-18",
    extraLarge: "h-22 w-22",
  };

  const sizeClasses = sizeClassMap[size];

  return (
    <div className="flex items-start gap-2 truncate">
      {imageUrl?.length ? (
        <img
          src={imageUrl}
          className={`${sizeClasses} shrink-0 rounded-full object-cover`}
        />
      ) : (
        <UserCircle2 size={48} />
      )}
      {useBuiltInHeader && (
        <div>
          <h3 className="text-lg font-bold">{titleToUse}</h3>
          <p className="text-muted-foreground text-sm">{site.companyName}</p>
        </div>
      )}
    </div>
  );
}
