import { z } from "zod";
import { ApiResponse, PHONE } from "./base-types";
import { S3PresignedPost } from "./photo";
import { MetaobjectBase, MetaobjectRef } from "./metaobject";

export const MAX_COMPLAINT_PHOTOS = 12;

export enum ComplaintStatus {
  Approved = "Approved",
  Rejected = "Rejected",
  UnderReview = "Under Review",
}

const zBaseForm = z.object({
  name: z.string().min(1, "Name is required"),
  phoneNumber: z.string().regex(PHONE.regex, PHONE.message),
  phoneCode: z.string().min(1, "Phone code is required"),
  missionCode: z.string().optional(),
  details: z.string().optional(),
});

export const zComplaintForm = zBaseForm.extend({
  date: z.string().min(1, "Date is required"),
  time: z.string().min(1, "Approximate time is required"),
  cost: z.string().min(1, "Cost is required"),
  photos: z
    .array(z.object({ value: z.string().url(), file: z.instanceof(File) }))
    .max(
      MAX_COMPLAINT_PHOTOS,
      `You can upload up to ${MAX_COMPLAINT_PHOTOS} photos.`,
    ),
});

export const zComplaintSubmit = zBaseForm.extend({
  date: z.string(),
  cost: z.number(),
  photos: z.array(z.object({ contentType: z.string() })),
});

export type ComplaintFormSubmit = z.infer<typeof zComplaintSubmit>;

export interface ComplaintSubmitResponse extends ApiResponse {
  stagedUploads: S3PresignedPost[];
}

export interface Complaint extends MetaobjectBase {
  status: ComplaintStatus;
  date: string;
  name: string;
  details: string | null;
  photos: Array<{ key: string; url?: string }> | null;
  cost: number;
  pilot: MetaobjectRef<{
    firstName?: { value: string };
    lastName?: { value: string };
    email?: { value: string | null };
  }>;
  lead: MetaobjectRef<{
    missionCode?: { value: string };
  }>;
  customer: MetaobjectRef<{
    phone?: { value: string };
    name?: { value: string | null };
  }>;
  review: MetaobjectRef | null;
  pilotAccountHandle: string;
}

export type ComplaintCreate = Pick<
  Complaint,
  | "date"
  | "name"
  | "details"
  | "photos"
  | "cost"
  | "pilotAccountHandle"
  | "status"
> & {
  pilot: string;
  lead: string;
  review: string;
  customer: string;
};

export type ComplaintUpdate = Partial<Pick<Complaint, "photos" | "status">>;
