import type { ProfilePublic } from "@ddr/models";
import { ReactTextLink } from "@ddr/ui";
import { LINKS } from "@ddr/utils";

export default function ProfileChecklist({
  muted = false,
  profile,
  equipmentLimit,
}: {
  muted?: boolean;
  profile?: ProfilePublic;
  equipmentLimit?: number;
}) {
  const classes = muted ? "text-muted-foreground" : "";
  const equipmentToShow =
    equipmentLimit !== undefined
      ? profile?.equipment?.slice(0, equipmentLimit)
      : (profile?.equipment ?? []);

  return (
    <ul className={`${classes} mb-2 list-inside list-disc`}>
      <li>
        Covered by{" "}
        <ReactTextLink
          text={LINKS.guarantee.text}
          href={LINKS.guarantee.href}
          external
        />
      </li>
      <li>DDR Approved Drone</li>
      <li>Commercial Drone License</li>
      {profile?.numberOfSearches ? (
        <li>{profile.numberOfSearches} completed searches</li>
      ) : null}
      {profile && equipmentToShow?.length ? (
        <li>{equipmentToShow.join(", ")}</li>
      ) : null}
    </ul>
  );
}
