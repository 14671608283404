import { Button } from "./button";
import { ArrowUpRight } from "lucide-react";
import { type ComponentProps } from "react";

interface ButtonLinkProps extends ComponentProps<typeof Button> {
  text: string;
  href: string;
  external?: boolean;
}

export function ReactButtonLink({
  text,
  href,
  external,
  ...props
}: ButtonLinkProps) {
  if (external) {
    return (
      <Button asChild {...props}>
        <a href={href} target="_blank" rel="noreferrer">
          <span>{text}</span>
          <ArrowUpRight size={20} />
        </a>
      </Button>
    );
  }

  return (
    <Button asChild {...props}>
      <a href={href}>{text}</a>
    </Button>
  );
}
