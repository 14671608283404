import React from "react";
import Facebook from "../assets/facebook.svg";
import Instagram from "../assets/instagram.svg";
import TikTok from "../assets/tiktok.svg";
import Youtube from "../assets/youtube.svg";
import LinkIcon from "../assets/link.svg";

interface SocialMediaProps {
  socialMedia: Array<{
    name: string;
    url: string;
  }>;
}

const SocialMediaIcons: React.FC<SocialMediaProps> = ({ socialMedia }) => {
  const renderIcon = (platform: string) => {
    switch (platform) {
      case "Facebook":
        return <img src={Facebook} alt="Facebook" className="my-2 h-4 w-4" />;
      case "YouTube":
        return <img src={Youtube} alt="YouTube" className="my-2 h-4 w-4" />;
      case "Instagram":
        return <img src={Instagram} alt="Instagram" className="my-2 h-4 w-4" />;
      case "TikTok":
        return <img src={TikTok} alt="TikTok" className="my-2 h-4 w-4" />;
      default:
        return <img src={LinkIcon} alt="Link" className="my-2 h-4 w-4" />;
    }
  };

  return (
    <div className="flex space-x-4">
      {socialMedia.map((media, index) => (
        <a
          key={index}
          href={media.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-lg font-semibold hover:text-blue-500"
        >
          {renderIcon(media.name)}
        </a>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
