import { cn } from "./utils";

type ErrorBoxProps = {
  children?: React.ReactNode;
  message?: string;
  className?: string;
};

export function NoItemsBox({ children, message, className }: ErrorBoxProps) {
  return (
    <div className={cn("mb-5 flex justify-center", className)}>
      <div className="flex flex-col items-center p-5 text-center">
        {children ?? (
          <>
            <h3 className="text-muted-foreground text-lg">{message}</h3>
          </>
        )}
      </div>
    </div>
  );
}
