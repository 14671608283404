import { cn } from "./utils";

type ErrorBoxProps = {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  className?: string;
};

export function ErrorBox({
  children,
  title,
  description,
  className,
}: ErrorBoxProps) {
  const titleToShow = title || "Sorry, something went wrong.";
  const descriptionToShow = description ?? "Our team has been informed.";

  return (
    <div className={cn("flex justify-center", className)}>
      <div className="flex flex-col items-center p-5 text-center">
        {children ?? (
          <>
            <h1 className="mb-2 text-xl font-bold">{titleToShow}</h1>
            <p className="text-muted-foreground mb-4 max-w-md">
              {descriptionToShow}
            </p>
          </>
        )}
      </div>
    </div>
  );
}
