import type { Metaobject, MetaobjectField } from "@ddr/models";

export const transformMetaobject = <T>(obj: Metaobject): T => {
  const { fields, field, ...rest } = obj;

  let transformed = { ...rest } as Record<string, unknown>;
  const status = obj.capabilities?.publishable?.status;
  if (status) transformed.status = status;

  // Give precedence to the value returned at top level rather than field/fields
  // e.g. pilot in review query is at top level with expanded reference details
  if (field?.key && !transformed[field.key]) {
    const parsedValue = parseMetaobjectField(field);
    transformed[field.key] = parsedValue;
  }

  const transformedFields = fields?.reduce<Record<string, unknown>>(
    (acc, curr) => {
      if (transformed[curr.key]) return acc;
      acc[curr.key] = parseMetaobjectField(curr);
      return acc;
    },
    {},
  );
  transformed = { ...transformed, ...transformedFields };

  return transformed as T;
};

const parseMetaobjectField = (field: MetaobjectField): unknown => {
  const needsJsonParse =
    field.type === "json" ||
    field.type.startsWith("list.") ||
    field.type === "rating" ||
    field.type === "boolean" ||
    field.type === "number_integer" ||
    field.type === "number_decimal";

  // NOTE: jsonValue is available from Admin API but not Storefront API
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let value =
    needsJsonParse && typeof field.value === "string"
      ? JSON.parse(field.value)
      : field.value;

  if (field.type === "metaobject_reference" && field.reference) {
    const ref = field.reference;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    value = transformMetaobject(ref);
  }

  // Use Shopify's rating field for Shopify pages (may be able to use theme components)
  // Map to a number for our app's usage
  if (field.type === "rating") {
    value = Number((value as { value: string }).value);
  }

  return value;
};
