import type { HandleErrFn } from "@ddr/models";
import { toast } from "@ddr/ui";
import * as Sentry from "@sentry/react";
import { ENV_NAME } from "../config";

export const defaultSentryTags = { appName: "widgets" };

/** Report error and show toast if message is defined */
export const handleError: HandleErrFn = (
  err: unknown,
  message?: string,
  description?: string,
) => {
  if (message) {
    toast({ title: message, description, variant: "destructive" });
  }
  if (ENV_NAME === "local") console.error(err);
  Sentry.captureException(err, { tags: { ...defaultSentryTags } });
};
