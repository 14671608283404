import type { ClientResponse } from "@shopify/storefront-api-client";

export const handleShopifyError = (res: ClientResponse): void => {
  if (res.errors) {
    const status = res.errors.networkStatusCode;
    const errorMessage = `[SHOPIFY ERROR] ${status}: ${res.errors.message}`;
    console.error(errorMessage);

    const gqlErrors = res.errors.graphQLErrors;
    if (gqlErrors?.length) {
      console.log("[GQL ERRORS]", JSON.stringify(gqlErrors, null, 2));
    }

    throw new Error(errorMessage);
  }

  if (res.data) {
    // Example Shopify response
    // { stagedUploadsCreate: { stagedTargets: []; userErrors: [] } }
    Object.values(res.data as object).forEach((value) => {
      if (!value) return;
      Object.entries(value as object).forEach(([key, value]) => {
        if (key !== "userErrors" || !(value as Array<unknown>).length) return;
        console.error("[USER ERRORS]", JSON.stringify(value, null, 2));
        throw new Error("Invalid data sent to Shopify.");
      });
    });
  }
};
