import type { HandleErrFn, Point } from "@ddr/models";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Input } from "./input";
import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { toast } from "./use-toast";

interface Props {
  onSelect: (option: Point) => void;
  className?: string;
  onError: HandleErrFn;
}

export default function LocationSearch({ onSelect, onError, ...props }: Props) {
  const [options, setOptions] = useState<Point[]>([]);
  const [debouncedSearchTerm, setSearchTerm] = useDebounceValue<string>(
    "",
    1000,
  );

  useEffect(() => {
    const trimmed = debouncedSearchTerm.trim();
    if (trimmed.length < 2) return;
    const fetchData = async () => {
      const geocoder = new window.google.maps.Geocoder();
      const response = await geocoder.geocode({
        address: trimmed,
        componentRestrictions: { country: "US" },
      });
      const options = response.results.map((result) => ({
        name: result.formatted_address,
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      }));
      setOptions(options);
    };
    fetchData().catch((err) => {
      if (err.message.includes("ZERO_RESULTS")) {
        toast({
          title: "No results found.",
          description: "Please try again with a different location.",
        });
      } else {
        onError(err, "Sorry, something went wrong with address lookup.");
      }
    });
  }, [debouncedSearchTerm, onSelect]);

  return (
    <>
      <Popover open={options.length > 0}>
        <PopoverTrigger {...props} tabIndex={-1}>
          <Input
            name="Location"
            data-1p-ignore
            data-lpignore="true"
            placeholder="Enter zip code or city"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </PopoverTrigger>

        <PopoverContent
          className="px-0 py-2"
          align="start"
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <ul>
            {options.map((option, i) => (
              <li
                key={option.name}
                onClick={() => {
                  onSelect(option);
                  setOptions([]);
                }}
                className="hover:bg-muted/50 cursor-pointer px-4 py-2 text-sm"
                tabIndex={i}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </PopoverContent>
      </Popover>
    </>
  );
}
