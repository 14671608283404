// Generic point interface for Google maps / other source
interface Point {
  lat: () => number;
  lng: () => number;
}

interface PointObj {
  lat: number;
  lng: number;
}

/** @returns Distance in `unit` (default miles) */
export const calculateDistance = (
  point1: Point | PointObj,
  point2: Point | PointObj,
  units: "km" | "mi" = "mi",
) => {
  const lat1 = typeof point1.lat === "function" ? point1.lat() : point1.lat;
  const lng1 = typeof point1.lng === "function" ? point1.lng() : point1.lng;
  const lat2 = typeof point2.lat === "function" ? point2.lat() : point2.lat;
  const lng2 = typeof point2.lng === "function" ? point2.lng() : point2.lng;

  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const km = R * c;
  return units === "km" ? km : km * 0.621371;
};

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}
