import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { ROOT_ELEMENT_ID } from "./config";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById(ROOT_ELEMENT_ID)!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
