export const reportGtmEvent = (
  name: string,
  campaign?: string,
  attrs?: Record<string, unknown>,
) => {
  try {
    const win = window as { dataLayer?: unknown[] };
    win.dataLayer = win.dataLayer || [];

    win.dataLayer.push({
      event: name,
      campaign: campaign,
      eventCategory: "TEST Category",
      ...attrs,
      // eventAction: "Action",
      // eventLabel: "Label",
      // eventValue: 1,
    });
  } catch (err) {
    console.error(err);
  }
};
