import { StateCode, StateDetails, StateMap } from "@ddr/models";

const CAMEL_TO_ABBREV: Record<string, StateCode> = {
  alabama: "AL",
  alaska: "AK",
  arizona: "AZ",
  arkansas: "AR",
  california: "CA",
  colorado: "CO",
  connecticut: "CT",
  delaware: "DE",
  florida: "FL",
  georgia: "GA",
  hawaii: "HI",
  idaho: "ID",
  illinois: "IL",
  indiana: "IN",
  iowa: "IA",
  kansas: "KS",
  kentucky: "KY",
  louisiana: "LA",
  maine: "ME",
  maryland: "MD",
  massachusetts: "MA",
  michigan: "MI",
  minnesota: "MN",
  mississippi: "MS",
  missouri: "MO",
  montana: "MT",
  nebraska: "NE",
  nevada: "NV",
  ohio: "OH",
  oklahoma: "OK",
  oregon: "OR",
  pennsylvania: "PA",
  tennessee: "TN",
  texas: "TX",
  utah: "UT",
  vermont: "VT",
  virginia: "VA",
  washington: "WA",
  wisconsin: "WI",
  wyoming: "WY",
  "new-hampshire": "NH",
  "new-jersey": "NJ",
  "new-mexico": "NM",
  "new-york": "NY",
  "north-carolina": "NC",
  "north-dakota": "ND",
  "rhode-island": "RI",
  "south-carolina": "SC",
  "south-dakota": "SD",
  "west-virginia": "WV",
};

export const STATE_DETAILS: Partial<StateMap> = {
  AL: {
    code: "AL",
    display: "Alabama",
    bounds: { east: -84.8, north: 35.0, south: 30.2, west: -88.5 },
    center: { name: "center of Alabama", lat: 32.806671, lng: -86.79113 },
  },
  AK: {
    code: "AK",
    display: "Alaska",
    bounds: { east: -129.0, north: 71.5, south: 51.2, west: -179.0 },
    center: { name: "center of Alaska", lat: 61.370716, lng: -152.404419 },
  },
  AZ: {
    code: "AZ",
    display: "Arizona",
    bounds: { east: -109.0, north: 37.0, south: 31.3, west: -114.8 },
    center: { name: "center of Arizona", lat: 34.168218, lng: -111.930507 },
  },
  AR: {
    code: "AR",
    display: "Arkansas",
    bounds: { east: -89.6, north: 36.5, south: 33.0, west: -94.6 },
    center: { name: "center of Arkansas", lat: 34.969704, lng: -92.373123 },
  },
  CA: {
    code: "CA",
    display: "California",
    bounds: { east: -114.5, north: 42.0, south: 32.5, west: -124.4 },
    center: { name: "center of California", lat: 37.271874, lng: -119.27 },
  },
  CO: {
    code: "CO",
    display: "Colorado",
    bounds: { east: -102.0, north: 41.0, south: 36.0, west: -109.0 },
    center: { name: "center of Colorado", lat: 39.550051, lng: -105.782067 },
  },
  CT: {
    code: "CT",
    display: "Connecticut",
    bounds: { east: -71.1, north: 42.0, south: 40.9, west: -73.7 },
    center: { name: "center of Connecticut", lat: 41.603221, lng: -73.087749 },
  },
  DE: {
    code: "DE",
    display: "Delaware",
    bounds: { east: -75.4, north: 39.8, south: 38.4, west: -75.8 },
    center: { name: "center of Delaware", lat: 39.739853, lng: -75.538208 },
  },
  FL: {
    code: "FL",
    display: "Florida",
    bounds: { east: -80.0, north: 31.0, south: 24.4, west: -87.6 },
    center: { name: "center of Florida", lat: 27.766279, lng: -81.686783 },
  },
  GA: {
    code: "GA",
    display: "Georgia",
    bounds: { east: -80.0, north: 35.0, south: 30.4, west: -85.0 },
    center: { name: "center of Georgia", lat: 33.040619, lng: -83.643074 },
  },
  HI: {
    code: "HI",
    display: "Hawaii",
    bounds: { east: -154.8, north: 20.5, south: 18.5, west: -155.6 },
    center: { name: "center of Hawaii", lat: 21.094318, lng: -157.498337 },
  },
  ID: {
    code: "ID",
    display: "Idaho",
    bounds: { east: -111.0, north: 49.0, south: 41.0, west: -116.0 },
    center: { name: "center of Idaho", lat: 44.068203, lng: -114.74204 },
  },
  IL: {
    code: "IL",
    display: "Illinois",
    bounds: { east: -87.5, north: 42.5, south: 36.9, west: -91.5 },
    center: { name: "center of Illinois", lat: 40.633125, lng: -89.398528 },
  },
  IN: {
    code: "IN",
    display: "Indiana",
    bounds: { east: -84.8, north: 42.0, south: 37.8, west: -88.1 },
    center: { name: "center of Indiana", lat: 39.79149, lng: -86.145246 },
  },
  IA: {
    code: "IA",
    display: "Iowa",
    bounds: { east: -90.5, north: 43.5, south: 40.4, west: -96.5 },
    center: { name: "center of Iowa", lat: 42.029174, lng: -93.210526 },
  },
  KS: {
    code: "KS",
    display: "Kansas",
    bounds: { east: -94.6, north: 40.0, south: 36.0, west: -102.0 },
    center: { name: "center of Kansas", lat: 39.011902, lng: -98.484246 },
  },
  KY: {
    code: "KY",
    display: "Kentucky",
    bounds: { east: -81.9, north: 39.1, south: 36.5, west: -88.0 },
    center: { name: "center of Kentucky", lat: 37.839333, lng: -84.270018 },
  },
  LA: {
    code: "LA",
    display: "Louisiana",
    bounds: { east: -88.0, north: 33.0, south: 28.9, west: -94.0 },
    center: { name: "center of Louisiana", lat: 31.169546, lng: -91.867805 },
  },
  ME: {
    code: "ME",
    display: "Maine",
    bounds: { east: -66.9, north: 47.5, south: 43.0, west: -71.1 },
    center: { name: "center of Maine", lat: 45.367584, lng: -68.972168 },
  },
  MD: {
    code: "MD",
    display: "Maryland",
    bounds: { east: -75.0, north: 39.5, south: 37.9, west: -79.5 },
    center: { name: "center of Maryland", lat: 39.045754, lng: -76.641273 },
  },
  MA: {
    code: "MA",
    display: "Massachusetts",
    bounds: { east: -69.9, north: 42.5, south: 41.0, west: -73.5 },
    center: { name: "center of Massachusetts", lat: 42.4072, lng: -71.3824 },
  },
  MI: {
    code: "MI",
    display: "Michigan",
    bounds: { east: -82.2, north: 48.3, south: 41.7, west: -90.4 },
    center: { name: "center of Michigan", lat: 44.314848, lng: -85.602364 },
  },
  MN: {
    code: "MN",
    display: "Minnesota",
    bounds: { east: -89.5, north: 49.5, south: 43.5, west: -96.6 },
    center: { name: "center of Minnesota", lat: 46.39241, lng: -94.63623 },
  },
  MS: {
    code: "MS",
    display: "Mississippi",
    bounds: { east: -88.0, north: 35.0, south: 30.2, west: -91.6 },
    center: { name: "center of Mississippi", lat: 32.741646, lng: -89.678696 },
  },
  MO: {
    code: "MO",
    display: "Missouri",
    bounds: { east: -89.1, north: 40.58, south: 35.99, west: -95.7 },
    center: { name: "center of Missouri", lat: 37.964253, lng: -91.831833 },
  },
  MT: {
    code: "MT",
    display: "Montana",
    bounds: { east: -104.0, north: 49.0, south: 44.5, west: -116.0 },
    center: { name: "center of Montana", lat: 46.96526, lng: -110.513274 },
  },
  NE: {
    code: "NE",
    display: "Nebraska",
    bounds: { east: -95.3, north: 43.0, south: 40.0, west: -104.0 },
    center: { name: "center of Nebraska", lat: 41.492537, lng: -99.90181 },
  },
  NV: {
    code: "NV",
    display: "Nevada",
    bounds: { east: -114.0, north: 42.0, south: 35.0, west: -120.0 },
    center: { name: "center of Nevada", lat: 38.502003, lng: -117.020358 },
  },
  OH: {
    code: "OH",
    display: "Ohio",
    bounds: { east: -80.5, north: 41.8, south: 38.4, west: -84.8 },
    center: { name: "center of Ohio", lat: 40.417287, lng: -82.907123 },
  },
  OK: {
    code: "OK",
    display: "Oklahoma",
    bounds: { east: -94.4, north: 37.0, south: 33.0, west: -103.0 },
    center: { name: "center of Oklahoma", lat: 35.46756, lng: -96.779162 },
  },
  OR: {
    code: "OR",
    display: "Oregon",
    bounds: { east: -116.5, north: 46.3, south: 41.9, west: -124.5 },
    center: { name: "center of Oregon", lat: 43.804133, lng: -120.554201 },
  },
  PA: {
    code: "PA",
    display: "Pennsylvania",
    bounds: { east: -74.7, north: 42.3, south: 39.7, west: -80.5 },
    center: { name: "center of Pennsylvania", lat: 41.203323, lng: -77.194527 },
  },
  TN: {
    code: "TN",
    display: "Tennessee",
    bounds: { east: -82.2, north: 36.6, south: 35.0, west: -90.3 },
    center: { name: "center of Tennessee", lat: 35.517491, lng: -86.580447 },
  },
  TX: {
    code: "TX",
    display: "Texas",
    bounds: { east: -93.6, north: 36.5, south: 25.8, west: -106.5 },
    center: { name: "center of Texas", lat: 31.968599, lng: -99.90181 },
  },
  UT: {
    code: "UT",
    display: "Utah",
    bounds: { east: -109.5, north: 42.0, south: 36.0, west: -114.0 },
    center: { name: "center of Utah", lat: 39.32098, lng: -111.093731 },
  },
  VT: {
    code: "VT",
    display: "Vermont",
    bounds: { east: -71.5, north: 45.0, south: 42.7, west: -73.5 },
    center: { name: "center of Vermont", lat: 44.558803, lng: -72.577841 },
  },
  VA: {
    code: "VA",
    display: "Virginia",
    bounds: { east: -75.2, north: 39.5, south: 36.5, west: -83.7 },
    center: { name: "center of Virginia", lat: 37.431573, lng: -78.656894 },
  },
  WA: {
    code: "WA",
    display: "Washington",
    bounds: { east: -116.9, north: 49.0, south: 45.5, west: -124.8 },
    center: { name: "center of Washington", lat: 47.751076, lng: -120.740135 },
  },
  WI: {
    code: "WI",
    display: "Wisconsin",
    bounds: { east: -87.5, north: 47.0, south: 42.5, west: -92.5 },
    center: { name: "center of Wisconsin", lat: 43.78444, lng: -88.787868 },
  },
  WY: {
    code: "WY",
    display: "Wyoming",
    bounds: { east: -104.0, north: 45.0, south: 41.0, west: -111.0 },
    center: { name: "center of Wyoming", lat: 43.075968, lng: -107.290283 },
  },
  NH: {
    code: "NH",
    display: "New Hampshire",
    bounds: { east: -70.5, north: 45.3, south: 42.7, west: -72.6 },
    center: { name: "center of New Hampshire", lat: 43.19385, lng: -71.57239 },
  },
  NJ: {
    code: "NJ",
    display: "New Jersey",
    bounds: { east: -73.8, north: 41.4, south: 38.9, west: -75.0 },
    center: { name: "center of New Jersey", lat: 40.298904, lng: -74.521011 },
  },
  NM: {
    code: "NM",
    display: "New Mexico",
    bounds: { east: -103.0, north: 37.0, south: 31.3, west: -108.3 },
    center: { name: "center of New Mexico", lat: 34.840515, lng: -106.248482 },
  },
  NY: {
    code: "NY",
    display: "New York",
    bounds: { east: -73.2, north: 45.0, south: 40.5, west: -79.76 },
    center: { name: "center of New York", lat: 43.299428, lng: -74.217933 },
  },
  NC: {
    code: "NC",
    display: "North Carolina",
    bounds: { east: -75.5, north: 36.6, south: 33.8, west: -84.3 },
    center: { name: "center of North Carolina", lat: 35.63006, lng: -79.80641 },
  },
  ND: {
    code: "ND",
    display: "North Dakota",
    bounds: { east: -96.5, north: 49.0, south: 46.9, west: -104.0 },
    center: { name: "center of North Dakota", lat: 47.528912, lng: -99.784012 },
  },
  RI: {
    code: "RI",
    display: "Rhode Island",
    bounds: { east: -71.1, north: 42.1, south: 41.1, west: -71.6 },
    center: { name: "center of Rhode Island", lat: 41.580095, lng: -71.477429 },
  },
  SC: {
    code: "SC",
    display: "South Carolina",
    bounds: { east: -78.5, north: 35.2, south: 32.0, west: -83.4 },
    center: { name: "center of South Carolina", lat: 33.85689, lng: -80.945 },
  },
  SD: {
    code: "SD",
    display: "South Dakota",
    bounds: { east: -96.5, north: 45.9, south: 42.5, west: -104.0 },
    center: { name: "center of South Dakota", lat: 44.299782, lng: -99.438828 },
  },
  WV: {
    code: "WV",
    display: "West Virginia",
    bounds: { east: -77.8, north: 40.5, south: 37.2, west: -82.6 },
    center: { name: "center of West Virginia", lat: 38.59762, lng: -80.4549 },
  },
};

/** Full state name in camelcase or uppercase state abbreviation */
export const getStateDetails = (name: string): StateDetails | undefined => {
  if (STATE_DETAILS[name as StateCode]) return STATE_DETAILS[name as StateCode];

  if (CAMEL_TO_ABBREV[name]) {
    const abbrev = CAMEL_TO_ABBREV[name];
    return STATE_DETAILS[abbrev];
  }
};
