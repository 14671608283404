const classMap = {
  light: "border-white",
  dark: "border-slate-900",
};

export const Spinner = ({
  variant = "dark",
}: {
  variant?: "light" | "dark";
}) => {
  const classes = classMap[variant];

  return (
    <div
      className={`h-5 w-5 animate-spin rounded-full border-b-2 border-t-2 ${classes}`}
    />
  );
};
