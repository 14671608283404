import { CONNECTIONS } from "@ddr/utils";
import { handleError } from "../utils/error";

const keys = { connections: "connections", phone: "phone" };

export interface Connection {
  pilot: string;
  date: string;
}

const getConnectionsSafe = (): Connection[] => {
  try {
    return JSON.parse(
      localStorage.getItem(keys.connections) ?? "[]",
    ) as Connection[];
  } catch (err) {
    handleError(err);
    return [];
  }
};

const filterConnections = (connections: Connection[], expiryHours: number) => {
  const now = Date.now();
  const expiryMs = expiryHours * 60 * 60 * 1000;
  return connections.filter((c) => {
    const msElapsed = now - new Date(c.date).getTime();
    return expiryMs > msElapsed;
  });
};

export const getConnections = (): {
  allConnections: Connection[];
  activeConnections: Connection[];
  remainingConnections: number;
} => {
  const connections = getConnectionsSafe();
  const active = filterConnections(connections, CONNECTIONS.PERIOD_HRS);

  // Filter out connections that are too old to show contact info
  // This is also done when saving, but who knows when the last connection was saved
  const unexpired = filterConnections(
    connections,
    CONNECTIONS.SHOW_CONTACT_INFO_PERIOD_HRS,
  );

  let remaining = CONNECTIONS.MAX - active.length;
  if (remaining < 0) remaining = 0;

  return {
    allConnections: unexpired,
    activeConnections: active,
    remainingConnections: remaining,
  };
};

export const addConnection = (pilot: string) => {
  const connections = getConnectionsSafe();

  // Don't count contacts with same pilot twice, but overwrite the existing one with new date
  // Contact button will be disabled if the pilot is already connected
  const withoutCurrent = connections.filter((c) => c.pilot !== pilot);

  // Remove connections older than SHOW_CONTACT_INFO_PERIOD_HRS
  const filtered = filterConnections(
    withoutCurrent,
    CONNECTIONS.SHOW_CONTACT_INFO_PERIOD_HRS,
  );

  const updatedConnections = [
    ...filtered,
    { pilot, date: new Date().toISOString() },
  ];
  localStorage.setItem(keys.connections, JSON.stringify(updatedConnections));
};

export const getPhone = (): string | null => {
  try {
    return localStorage.getItem(keys.phone);
  } catch (err) {
    handleError(err);
    return null;
  }
};

export const setPhone = (phone: string): void => {
  localStorage.setItem(keys.phone, phone);
};

export const getConnectionsMap = (
  connections: Connection[],
): Map<string, Connection> => {
  const map = new Map<string, Connection>();
  connections.forEach((c) => map.set(c.pilot, c));
  return map;
};
