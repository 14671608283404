type RatingProps = {
  rating: number;
  showLabel?: boolean;
  totalRatings?: number;
  size?: number;
  variant?: "mono" | "yellow";
};

type IconStarProps = {
  className?: string;
  size?: number;
};

export const IconStar = ({ className, size }: IconStarProps) => {
  return (
    <svg
      width={size ?? 24}
      height={size ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.8649 2.99628C12.4796 2.33213 11.5204 2.33213 11.1351 2.99628L8.42101 7.67359C8.23064 8.00168 7.90159 8.22557 7.52653 8.28222L2.44021 9.05044C1.58593 9.17947 1.28627 10.2581 1.95158 10.8093L5.74067 13.9485C6.09141 14.2391 6.25633 14.6975 6.17113 15.1449L5.17996 20.35C5.02327 21.1729 5.88706 21.8122 6.62821 21.4219L11.4176 18.9001C11.7821 18.7082 12.2178 18.7082 12.5824 18.9001L17.3718 21.4219C18.1129 21.8122 18.9767 21.1729 18.82 20.35L17.8289 15.1449C17.7437 14.6975 17.9086 14.2391 18.2593 13.9485L22.0484 10.8093C22.7137 10.2581 22.4141 9.17947 21.5598 9.05044L16.4735 8.28222C16.0984 8.22557 15.7694 8.00168 15.579 7.67359L12.8649 2.99628Z"
        fill="currentColor"
      />
    </svg>
  );
};

const starClassVariants = {
  mono: "text-[#080e1c] fill-[#080e1c]",
  yellow: "text-[#fabc05] fill-[#fabc05]",
};

export const Rating = ({
  rating,
  size,
  showLabel,
  totalRatings,
  variant = "yellow",
}: RatingProps) => {
  const max = 5;
  /* Calculate how much of the stars should be "filled" */
  const percentage = Math.round((rating / max) * 100);
  const ratingLabel = rating?.toLocaleString("en", { minimumFractionDigits: 1 });
  const showTotal = totalRatings !== undefined;

  return (
    <div className="flex items-start">
      {showLabel && <p className="mr-1 text-lg">{ratingLabel}</p>}

      <div className="relative inline-flex items-center">
        {/* Create an array based on the max rating, render a star for each */}
        {Array.from(Array(max).keys()).map((_, i) => (
          <IconStar
            key={String(i)}
            className={`flex ${starClassVariants[variant]}`}
            size={size}
          />
        ))}
        {/* Render a div overlayed on top of the stars that are not filled */}
        <div
          className="z-1 absolute bottom-0 right-0 top-0 bg-[#fefefe] opacity-70"
          style={{ width: `${100 - percentage}%` }}
        />
      </div>

      {showTotal && <p className="ml-1 text-lg">({totalRatings})</p>}
    </div>
  );
};
