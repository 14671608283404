import { ArrowUpRight } from "lucide-react";

interface TextLinkProps {
  text: string;
  href: string;
  external?: boolean;
}

/** Link styled as plain text with optional external icon */
export function ReactTextLink({ text, href, external }: TextLinkProps) {
  if (external) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="inline-flex items-center underline"
      >
        <span>{text}</span>
        <ArrowUpRight size={20} />
      </a>
    );
  }

  return (
    <a href={href} className="inline-flex underline">
      {text}
    </a>
  );
}
