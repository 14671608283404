/*
 * Use Intl.DateTimeFormat to format a date
 * Initialize Intl.DateTimeFormat objects here so they can cache locale db lookups
 *
 * Notes about caching via Intl.DateTimeFormat
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
 */

type DateFormat = "date" | "datetime" | "shortdate";

const defaultDate = Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

const shortDate = Intl.DateTimeFormat("en-US", {
  year: "2-digit",
  month: "numeric",
  day: "numeric",
});

const defaultDateTime = Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

/** @param date ISO 8601 date string or Date object */
export const formatDate = (
  date: string | Date,
  format: DateFormat = "date",
): string => {
  const dateObj = typeof date === "string" ? new Date(date) : date;

  if (format === "date") {
    return defaultDate.format(dateObj);
  } else if (format === "datetime") {
    return defaultDateTime.format(dateObj);
  } else if (format === "shortdate") {
    return shortDate.format(dateObj);
  }

  return defaultDate.format(dateObj);
};

/** Get ms between now and then. Params must be date or ISO string */
export const msDiff = (a: Date | string, b: Date | string): number => {
  const aDate = typeof a === "string" ? new Date(a) : a;
  const bDate = typeof b === "string" ? new Date(b) : b;
  return aDate.getTime() - bDate.getTime();
};

export const hrsDiff = (a: Date | string, b: Date | string): number => {
  return msDiff(a, b) / 1000 / 60 / 60;
};

export const hrsAgo = ({
  now,
  then,
}: {
  now: Date | string;
  then: Date | string;
}): number => {
  return msDiff(now, then) / 1000 / 60 / 60;
};
