import { MetaobjectStatus, ProfileSite, ProfileStatus } from "@ddr/models";

export const getStatus = (status: MetaobjectStatus): string => {
  switch (status) {
    case "ACTIVE":
      return "Live";
    case "DRAFT":
      return "Under Review";
  }
};

export const getProfileStatus = (status: ProfileStatus): string => {
  switch (status) {
    case "draft":
      return "Draft";
    case "under-review":
      return "Under Review";
    case "approved":
      return "Approved";
  }
};

export const getUrlPathForError = (url: string): string => {
  try {
    let pathname = url;
    if (url) pathname = new URL(url).pathname;
    if (pathname.length < 2) pathname = url;
    return pathname.replaceAll(/[0-9]{13}/g, "[id]");
  } catch (err) {
    console.error(err);
    return url;
  }
};
