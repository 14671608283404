import { Button, ButtonProps } from "./button";
import { Spinner } from "./spinner";

interface ActionBtnProps extends ButtonProps {
  children: React.ReactNode;
  loading?: boolean;
}

type BtnVariant = NonNullable<ButtonProps["variant"]>;

const spinnerVariants: Record<BtnVariant, "light" | "dark"> = {
  default: "light",
  destructive: "light",
  outline: "dark",
  secondary: "dark",
  ghost: "dark",
  link: "dark",
  brand: "dark",
};

export const ActionBtn = ({
  children,
  onClick,
  loading,
  ...props
}: ActionBtnProps) => {
  const variant = props.variant ?? "default";

  return (
    <Button {...props} onClick={onClick}>
      {loading ? <Spinner variant={spinnerVariants[variant]} /> : children}
    </Button>
  );
};
