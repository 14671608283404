import { getUrlPathForError } from "@ddr/utils";
import type { FetchJsonRes } from "@ddr/models";

export const fetchJson = async <T = unknown>(
  url: string,
  init?: RequestInit & { throwStatus?: boolean },
): Promise<FetchJsonRes<T>> => {
  let res;
  const urlForReport = getUrlPathForError(url);
  const throwStatus = init?.throwStatus ?? true;

  try {
    res = await fetch(url, init);
  } catch (err) {
    throw new Error(`Failed to fetch ${urlForReport}`, { cause: err });
  }

  let err = null;
  if (!res.ok) {
    err = new Error(`HTTP Error [${res.status}] from ${urlForReport}`);
    if (throwStatus) throw err;
  }

  const data = (await res.json()) as T;

  const enhancedResponse: FetchJsonRes<T> = { ...res, data };
  if (err) enhancedResponse.err = err;

  return enhancedResponse;
};
